<template>
  <elevator-archives page-type="draft"></elevator-archives>
</template>
<script>
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import elevatorArchives from 'V/facilitiesEquipment/elevator/elevatorArchives/Index.vue'
export default {
  name: 'elevatorDraft',
  components: {
    elevatorArchives,
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
}
</script>